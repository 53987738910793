import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import { Theme } from './theme'

interface Props {
    theme: Theme
}

const GlobalStyles = createGlobalStyle<Props>`
    ${normalize}

    html {
        box-sizing: border-box;
        height: 100%;
    }

    body {
        background: ${({ theme }) => theme.colors.background.primary};
        color: ${({ theme }) => theme.colors.text.onPrimary};
        font-family: ${({ theme }) => theme.typography.regularFont};
        font-size: ${({ theme }) => theme.typography.baseFontSize};
        height: inherit;
        margin: 0;
    }

    #root {
        height: inherit;
    }

    *, *::after, *::before {
        box-sizing: inherit;
    }

    #root {
        height: inherit;
    }

    img {
        width: 100%;
        height: auto;
    }

    h1, h2, h3, h4, h5 {
        color: ${({ theme }) => theme.colors.text.headingOnPrimary};
        font-weight: 500;
        margin-top: 0;
    }

    h1 {
        font-size: ${({ theme }) => theme.typography.typeScale.h1};
        line-height: 4.75rem;
        letter-spacing: 0.26px;
        margin-bottom: 1.25rem;
    }

    h2 {
        font-size: ${({ theme }) => theme.typography.typeScale.h2};
        line-height: 2.88rem;
        letter-spacing: 0.02px;
        margin-bottom: 1.25rem;
    }

    h3 {
        font-size: ${({ theme }) => theme.typography.typeScale.h3};
        line-height: 1.5rem;
        letter-spacing: 0.02px;
        margin-bottom: 0.5rem;
    }

    p {
        font-size: ${({ theme }) => theme.typography.typeScale.medium};
        letter-spacing: 0.15px;
        line-height: 1.562rem;
        margin-bottom: 1rem;
        margin-top: 0;
    }

    ul {
        padding: 0;
        margin: 0;
    }
`

export default GlobalStyles
