import {
    IGuideline,
    IGuidelineProduct,
    IApiResponse,
    IGuidelineApiResponse,
    IGuidelineItemApiResponse,
    IGuidelines,
} from '~/ts/interfaces'

function CreateGuidelines(response: IApiResponse): IGuideline[] {
    const products = response.items as IGuidelineProduct[]
    const root = response.root
    if (products.length === 0) {
        return [] as IGuideline[]
    }
    const guidelines = products.reduce((acc, product) => {
        if (!acc.filter(guideline => guideline.Link === product.Link).length) {
            acc = [
                ...acc,
                {
                    FileType: product.FileType ?? '',
                    Language: product.Language ?? '',
                    LinkShort:
                        product.Link && product.Link.includes(`.${product.FileType}`)
                            ? product.Link.split(`.${product.FileType}`)[0]
                            : '',
                    Link: product.Link ?? '',
                    FullLink: product.Link ? `${root}${product.Link}` : '',
                    ProductGroup: product.ProductGroup ?? '',
                    Products: [product.Product],
                    Thumbnail: product.Thumbnail!==undefined ? `${root}${product.Thumbnail}` : undefined,
                    Title: product.Title ?? '',
                } as IGuideline,
            ]
        } else {
            acc = acc.map(guideline =>
                guideline.Link === product.Link
                    ? ({
                          ...guideline,
                          Products: [...guideline.Products, { ...product }],
                      } as IGuideline)
                    : guideline,
            )
        }

        return acc
    }, [] as IGuideline[])

    return guidelines
}
export function createGuidelinesObj(response: IGuidelineApiResponse): IGuidelines {
    // setGuidelines(CreateGuidelines(guidelineResponse))
    const mapped = response.items.reduce((map: IGuidelines, obj: IGuidelineItemApiResponse) => {
        const { Product, ...rest } = obj
        if (!map[rest.Title]) {
            map[rest.Title] = {
                ...rest,
                Link: rest.Link ? `${response.root}${rest.Link}`:'',
                Thumbnail: rest.Thumbnail ? `${response.root}${rest.Thumbnail}` : '',
                Products: [Product],
            }
        } else {
            map[rest.Title] = {
                ...map[rest.Title],
                Products: [...map[rest.Title].Products, Product],
            }
        }
        return map
    }, {} as IGuidelines)
    return mapped
}
export default CreateGuidelines
