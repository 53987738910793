import React, { useState, useEffect } from 'react'

import { Filters, Guidelines, Loading } from '~/components'
import { IGuidelineApiResponse, IProduct } from '~/ts/interfaces'
import { getGuidelines } from '../../services/api'

interface IFilteredProducts {
    products: IProduct[]
    productGroups: string[]
}

const FiltersAndGuidelines: React.FC = () => {
    const [guidelineResponse, setGuidelineResponse] = useState({
        items: [],
        total: 0,
        root: '',
    } as IGuidelineApiResponse)
    const [productGroups, setProductGroups] = useState<string[]>([])
    const [products, setProducts] = useState<IProduct[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        getGuidelines()
            .then((guidelineResponse: IGuidelineApiResponse) => {
                const { products, productGroups } = filterRelevantDropdownsData(guidelineResponse)
                setGuidelineResponse(guidelineResponse)
                setProductGroups(productGroups)
                setProducts(products)
                setLoading(false)
            })
            .catch(error => setError(error))
    }, [])

    function filterRelevantDropdownsData(
        guidelineResponse: IGuidelineApiResponse,
    ): IFilteredProducts {
        const relevantProductsGroups = new Set<string>()
        const relevateProductIds = new Set<string>()
        const relevantProducts: IProduct[] = []
        relevantProductsGroups.add('')
        guidelineResponse.items.forEach(item => {
            relevantProductsGroups.add(item.ProductGroup)
            if (!relevateProductIds.has(item.ProductId)) {
                relevateProductIds.add(item.ProductId)
                relevantProducts.push({
                    Product: item.Product,
                    ProductGroup: item.ProductGroup,
                    ProductId: item.ProductId,
                })
            }
        })

        return {
            products: relevantProducts.sort((a, b) =>
                a.Product > b.Product ? 1 : b.Product > a.Product ? -1 : 0,
            ),
            productGroups: Array.from(relevantProductsGroups).sort(),
        }
    }

    return (
        <>
            {!!error ? (
                <div>Error</div>
            ) : !loading ? (
                <>
                    <Filters productGroups={productGroups} products={products} />
                    <Guidelines guidelineResponse={guidelineResponse} />
                </>
            ) : (
                <Loading />
            )}
        </>
    )
}

export default FiltersAndGuidelines
