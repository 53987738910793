import { documentTypesReducer } from './documentTypes/reducer'
import { productgroupReducer } from './productGroup/reducer'
import { guidelineReducer } from './guidelines/reducer'
import { Actions } from 'store/actions'
import IStateData from '~/ts/interfaces/IStateData'

export const dataReducer = (
    { productGroup, documentTypes, guidelines, filteredGuidelines }: IStateData,
    action: Actions,
) => ({
    productGroup: productgroupReducer(productGroup, action),
    documentTypes: documentTypesReducer(documentTypes, action),
    guidelines: guidelineReducer(guidelines, action),
})
