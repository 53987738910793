import React from 'react'
import styled from 'styled-components'

interface Props {
    alt: string
    height?: string
    maxHeight?: string
    src: string
    width?: string
}

interface ContainerProps {
    width: string
    height: string
}

const Container = styled.div<ContainerProps>`
    align-items: flex-start;
    display: flex;
    height: ${({ height }) => height};
    justify-content: center;
    overflow: hidden;
    width: ${({ width }) => width};
`

const Img = styled.img<Props>`
    height: 100%;
    max-height: ${({ maxHeight }) => maxHeight};
    max-width: 100%;
    object-fit: cover;
    width: auto;

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
        height: auto;
        width: 100%;
    }
`

const Image = ({ src, alt, width = 'unset', height = 'unset', maxHeight = '350px' }: Props) => {
    return (
        <Container width={width} height={height}>
            {src && <Img src={src} alt={alt} maxHeight={maxHeight} />}
        </Container>
    )
}

export default Image
