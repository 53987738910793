import breakpoints, { Breakpoints } from './breakpoints'
import colors from './colors'
import sizes from './sizes'
import spacings, { Spacings } from './spacings'
import { baseFontSize, regularFont, typeScale, Typography } from './typography'

export {
    Breakpoints,
    Spacings,
    Typography,
    baseFontSize,
    breakpoints,
    colors,
    regularFont,
    sizes,
    spacings,
    typeScale,
}
