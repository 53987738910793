import { IState } from '~/ts/interfaces'
import { Actions } from './actions'
import { dataReducer } from './data/reducer'
import filtersReducer from './filters/reducer'
import applyMiddlewares from './middlewares'

export const mainReducer = (prevState: IState, action: Actions) => {
    const state = {
        data: dataReducer(prevState.data, action),
        filters: filtersReducer(prevState.filters, action),
    }

    // Fix here

    return applyMiddlewares({ prevState, state, action })
}
