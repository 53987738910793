import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { IProduct } from '~/ts/interfaces'
import { Dropdown, TextField, ResetFilters, QrScanner } from '~/components'
import { useStore, useStoreDispatch } from '~/store'
import FilterActions from '~/store/filters/actions'

const FiltersContainer = styled.section`
    background-color: ${({ theme }) => theme.colors.background.filter};
    margin: ${({
        theme: {
            spacings: { medium },
        },
    }) => `0 0 calc(${medium}*2) 0`};
    min-width: 277px;
    padding: 2.125rem;
`
const GridRow = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(calc(215px), 1fr));
`
interface IProductsByProductGroup {
    [key: string]: string[]
}

function createProductsByProductGroup(
    productGroups: string[] = [],
    products: IProduct[] = [],
): IProductsByProductGroup {
    const mapped = productGroups.reduce((obj: IProductsByProductGroup, item: string) => {
        obj[item] = []
        return obj
    }, {})

    products.forEach((p: IProduct) => {
        if (p.ProductGroup !== '' && mapped[p.ProductGroup]) {
            mapped[p.ProductGroup].push(p.Product)
        }
        // The empty mapped ProductGroup will include all the products available
        if (mapped['']) {
            mapped[''].push(p.Product)
        }
    })

    Object.keys(mapped).forEach(value => {
        if (mapped[value]) {
            mapped[value].unshift('')
        }
    })

    return mapped
}
interface FiltersProps {
    productGroups: string[]
    products: IProduct[]
}

const Filters: FC<FiltersProps> = ({ productGroups, products }) => {
    const { state } = useStore()
    const { dispatch } = useStoreDispatch()
    const { selectedProduct, selectedProductGroup, searchText } = state.filters
    const { setSelectedProduct, setSelectedProductGroup, setSearchText } = FilterActions
    const [productsByProductGroup, setProductsByProductGroup] = useState({})
    const [scanResult, setScanResult] = useState({data: null} as IScanData)
    const [showQrScanner, setShowQrScanner] = useState(false)

    const productsContainsSearchText = (products: IProduct[], searchText: string) => {
        return (products.findIndex(product => product.Product.toLowerCase().includes(searchText.toLowerCase()))) > 0
    }

    useEffect(() => {
        if (products.length > 0 && productGroups.length > 0) {
            const mapped = createProductsByProductGroup(productGroups, products)
            setProductsByProductGroup(mapped)
        }
    }, [products, productGroups])

    useEffect(() => {
        if (Object.keys(scanResult).length > 0 && scanResult.data !== null && products.length > 0) {
            const regex = new RegExp('\\.|\\s')
            const searchText = scanResult.data.split(regex)[0]

            if(productsContainsSearchText(products, searchText)) {
                setShowQrScanner(false)
                dispatch(setSearchText(searchText))
            }
        }
    }, [scanResult])

    return (
        <FiltersContainer>
            <GridRow>
                <QrScanner 
                    setScanResult={(scanResult) => setScanResult(scanResult)}
                    setShowQrScanner={(showQrScanner) => setShowQrScanner(showQrScanner)}
                    showQrScanner={showQrScanner}
                />
                <TextField
                    name="FreeText"
                    defaultValue={searchText}
                    placeholder="Sök på titel eller modell"
                    onChange={value => dispatch(setSearchText(value))}
                />
                <Dropdown
                    onSelectedItemChange={changes =>
                        dispatch(setSelectedProductGroup(changes.selectedItem))
                    }
                    selectedItem={selectedProductGroup ?? null}
                    items={productGroups}
                    label="Välj produkt kategori"
                />
                <Dropdown
                    onSelectedItemChange={changes =>
                        dispatch(setSelectedProduct(changes.selectedItem))
                    }
                    selectedItem={selectedProduct}
                    items={productsByProductGroup[selectedProductGroup] || []}
                    label="Välj produkt"
                />
            </GridRow>
            <ResetFilters
                onClick={() => dispatch(FilterActions.resetFilters())}
                text="Rensa filter"
            />
        </FiltersContainer>
    )
}

export default Filters
