import { useState, useEffect, Dispatch, SetStateAction } from 'react'

function useDebounce<T>(initialValue: T, delay: number): [T, T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState(initialValue)
    const [debouncedValue, setDebouncedValue] = useState(initialValue)

    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(debounce)
        }
    }, [value, delay])

    return [debouncedValue, value, setValue]
}

export default useDebounce
