import { Actions } from 'store/actions'
import { FilterActionTypes } from '~/ts/enums'
import { IFilter } from '~/ts/interfaces'
import {initialFilterState}from './initialFilterState'

const filtersReducer = (state: IFilter, action: Actions) => {
    switch (action.type) {
        case FilterActionTypes.SET_FILTERS: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case FilterActionTypes.SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload,
            }
        case FilterActionTypes.SET_SELECTED_PRODUCT_GROUP:
            return {
                ...state,
                selectedProduct: '',
                selectedProductGroup: action.payload,
            }
        case FilterActionTypes.SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload,
            }
        case FilterActionTypes.RESET_FILTERS:
            return {
                ...initialFilterState,
            }
        default:
            return state
    }
}
export default filtersReducer
