import { Actions } from 'store/actions'
import DocumentActionTypes from '~/ts/enums/DocumentActionTypes'
import { IDocumentTypesState } from '~/ts/interfaces'

export const documentTypesReducer = (state: IDocumentTypesState, action: Actions) => {
    switch (action.type) {
        case DocumentActionTypes.FETCHING:
            return {
                ...state,
                isFetching: true,
            }
        case DocumentActionTypes.FETCHED:
            return {
                ...state,
                isFetching: false,
                data: state.data,
            }
        case DocumentActionTypes.REJECTED:
            return {
                ...state,
                isFetching: false,
            }
        default:
            return state
    }
}
