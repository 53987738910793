import styled from 'styled-components'

const DefaultLink = styled.a`
    align-items: center;
    border-radius: 0.13rem;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: ${({ theme }) => theme.typography.typeScale.button};
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacings.xsmall};
    padding: ${({
        theme: {
            spacings: { small, medium },
        },
    }) => `calc(${small} - 1px) calc(${medium} - 1px)`};
`

export const PrimaryLink = styled(DefaultLink)`
    background-color: ${({ theme }) => theme.colors.buttons.primary};
    border-color: ${({ theme }) => theme.colors.buttons.primaryBorder};
    color: ${({ theme }) => theme.colors.buttons.primaryText};

    &:hover,
    &:active {
        border-color: ${({ theme }) => theme.colors.buttons.primaryHover};
        background-color: ${({ theme }) => theme.colors.buttons.primaryHover};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.colors.buttons.primaryDisabled};
        background-color: ${({ theme }) => theme.colors.buttons.primaryDisabled};
    }
`

export const SecondaryLink = styled(DefaultLink)`
    background-color: ${({ theme }) => theme.colors.buttons.secondary};
    border-color: ${({ theme }) => theme.colors.buttons.secondaryBorder};
    color: ${({ theme }) => theme.colors.buttons.secondaryText};

    &:hover,
    &:active {
        border-color: ${({ theme }) => theme.colors.buttons.primaryHover};
        background-color: ${({ theme }) => theme.colors.buttons.primaryHover};
        color: ${({ theme }) => theme.colors.buttons.primaryText};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.colors.buttons.primaryDisabled};
        background-color: ${({ theme }) => theme.colors.buttons.primaryDisabled};
    }
`

export const TernaryLink = styled(DefaultLink)`
    color: ${({ theme }) => theme.colors.buttons.ternaryText};

    &:hover {
        text-decoration: underline;
    }
`
