import history from '../../routerHistory'
import queryString from 'query-string'
import { Actions } from '../actions'
import { FilterActionTypes } from '~/ts/enums'
import { IState } from '~/ts/interfaces'

export const changeUrl = (state: IState, action: Actions) => {
    switch (action.type) {
        case FilterActionTypes.SET_SELECTED_PRODUCT:
        case FilterActionTypes.SET_SELECTED_PRODUCT_GROUP:
        case FilterActionTypes.SET_SEARCH_TEXT:
        case FilterActionTypes.RESET_FILTERS:
            const search = queryString.stringify(
                { ...state.filters },
                {
                    skipEmptyString: true,
                },
            )
            history.push({
                ...location,
                search: search ? `?${search}`:'',
            })
            break
    }
    return state
}
