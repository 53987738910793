import React from 'react'
import styled from 'styled-components'

import { Logo } from '../'

const Container = styled.header`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    max-width: 1440px;
    padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.layoutWrapper}`};
    width: 100%;
`

const Text = styled.h1`
    color: ${({ theme }) => theme.colors.text.headingOnHeader};
    font-size: 1.125rem;
    text-transform: uppercase;
    margin: 0;
    line-height: normal;
`

const Header = () => {
    return (
        <Container>
            <Logo />
            <Text>SVERIGE</Text>
        </Container>
    )
}

export default Header
