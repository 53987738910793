export interface TypeScale {
    large: string
    medium: string
    small: string
    button: string
    h1: string
    h2: string
    h3: string
}

export interface Typography {
    baseFontSize: string
    regularFont: string
    typeScale: TypeScale
}

const fallbackFonts = `"helvetica","arial","sans-serif"`
export const regularFont = `"helvetica neue", ${fallbackFonts}`
export const baseFontSize = '16px'
export const typeScale: TypeScale = {
    large: '1.06rem',
    medium: '1rem',
    small: '0.75rem',
    button: '0.94em',
    h1: '4.38rem',
    h2: '2rem',
    h3: '1.56rem',
}
