export interface Breakpoints {
    mobileS: string
    mobileM: string
    mobileL: string
    mobile: string
    tablet: string
    laptop: string
    laptopL: string
    desktop: string
}

const breakpoints: Breakpoints = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobile: '576px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
}

export default breakpoints
