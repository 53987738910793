import {
    Breakpoints,
    Spacings,
    Typography,
    baseFontSize,
    breakpoints,
    colors,
    regularFont,
    spacings,
    typeScale,
} from './tokens'

export interface Colors {
    background: {
        border: string
        filter: string
        primary: string
        secondary: string
        ternary: string
        tertiary: string
    }
    text: {
        headingOnHeader: string
        headingOnPrimary: string
        headingOnSecondary: string
        linkOnPrimary: string
        linkOnSecondary: string
        listItem: string
        onPrimary: string
        onSecondary: string
        onTernary: string
    }
    buttons: {
        primary: string
        primaryBorder: string
        primaryDisabled: string
        primaryHover: string
        primaryText: string
        secondary: string
        secondaryBorder: string
        secondaryDisabled: string
        secondaryText: string
        secondaryTextDisabled: string
        submit: string
        submitDisabled: string
        submitText: string
        ternaryText: string
    }
    dropdowns: {
        primary: string
        primaryBorder: string
        primaryBorderOpen: string
        primaryDisabled: string
        primaryHighlighted: string
        primaryListText: string
        primaryText: string
        primaryTextDisabled: string
    }
}

export interface Theme {
    breakpoints: Breakpoints
    colors: Colors
    spacings: Spacings
    typography: Typography
}

export interface SubTheme {
    colors: Colors
}

const defaultTheme = {
    breakpoints,
    typography: {
        baseFontSize,
        regularFont,
        typeScale,
    },
    spacings,
}

const light: SubTheme = {
    colors: {
        background: {
            border: colors.neutral[200],
            filter: colors.neutral[150],
            primary: colors.neutral[100],
            secondary: colors.red[200],
            ternary: colors.neutral[1000],
            tertiary: colors.neutral[600],
        },
        text: {
            headingOnHeader: colors.neutral[800],
            headingOnPrimary: colors.neutral[950],
            headingOnSecondary: colors.neutral[100],
            onPrimary: colors.neutral[500],
            onSecondary: colors.neutral[100],
            onTernary: colors.neutral[100],
            linkOnPrimary: colors.blue[100],
            linkOnSecondary: colors.neutral[100],
            listItem: colors.neutral[950],
        },
        buttons: {
            primary: colors.red[100],
            primaryBorder: colors.red[100],
            primaryDisabled: colors.red[50],
            primaryHover: colors.red[200],
            primaryText: colors.neutral[100],
            secondary: colors.neutral[100],
            secondaryBorder: colors.red[100],
            secondaryDisabled: colors.red[75],
            secondaryText: colors.red[100],
            secondaryTextDisabled: colors.red[100],
            submit: colors.red[100],
            submitDisabled: colors.neutral[300],
            submitText: colors.neutral[100],
            ternaryText: colors.blue[100],
        },
        dropdowns: {
            primary: colors.neutral[100],
            primaryBorder: colors.neutral[200],
            primaryBorderOpen: colors.neutral[800],
            primaryDisabled: colors.neutral[200],
            primaryHighlighted: colors.neutral[150],
            primaryListText: colors.neutral[900],
            primaryText: colors.neutral[200],
            primaryTextDisabled: colors.neutral[100],
        },
    },
}

export const lightTheme: Theme = { ...defaultTheme, ...light }
