import React, { FC, useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import SearchIcon from '../SearchIcon/SearchIcon'
import useDebounce from '~/utils/hooks/useDebounce'
import { Theme } from '~/utils/theme/theme'

const Container = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;
    border: none;
`

const Input = styled.input`
    display: block;
    color: ${({ theme }) => theme.colors.dropdowns.primaryListText};
    border: ${({ theme }) => `1px solid ${theme.colors.dropdowns.primaryBorder}`};
    border-radius: 3px;
    position: relative;
    padding: 0.9375rem 49px 0.9375rem 0.9375rem;
    line-height: 1.5625rem;
    justify-self: center;
    font-size: 1rem;
    cursor: text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    width: 100%;
    padding-right: calc(3 * 1.4375rem);

    &:hover {
        border: ${({ theme }) => `1px solid ${theme.colors.dropdowns.primaryBorderOpen}`};
    }
    &::placeholder {
        color: #${({ theme }) => `1px solid ${theme.colors.dropdowns.primaryText}`};
    }
`
interface ITextFieldProps {
    onChange?: (value: string) => void
    placeholder: string
    theme?: Theme
    defaultValue?: string
    name?: string
}

const TextField: FC<ITextFieldProps> = ({
    onChange,
    placeholder = '',
    defaultValue = '',
    name = '',
}) => {
    const [debouncedValue, value, setValue] = useDebounce(defaultValue, 700)
    const [skipFirst, setSkipFirst] = useState(true)

    useEffect(() => {
        if (!onChange) {
            return
        }

        if (skipFirst) {
            setSkipFirst(false)
        } else {
            onChange(debouncedValue)
        }
    }, [debouncedValue])

    useEffect(() => {
        if (defaultValue !== undefined) {
            setValue(defaultValue)
        }
    }, [defaultValue])

    return (
        <Container>
            <Input
                name={name}
                onChange={e => setValue(e.target.value)}
                placeholder={placeholder}
                type="text"
                value={value}
            />
            <SearchIcon />
        </Container>
    )
}
export default withTheme(TextField)
