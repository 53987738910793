import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.main`
    flex: 1;
    max-width: 1440px;
    padding: 0 ${({ theme }) => theme.spacings.layoutWrapper};
    width: 100%;
`

const Main: FC = ({ children }) => {
    return <Container>{children}</Container>
}

export default Main
