import React, { FC } from 'react'
import styled from 'styled-components'

const Img = styled.img`
    border-right: 1px solid ${({ theme }) => theme.colors.background.filter};
    margin-right: ${({ theme }) => theme.spacings.intermediate};
    max-width: 103px;
    padding-right: ${({ theme }) => theme.spacings.intermediate};
`

const Logo: FC = () => {
    const logo = require('~/assets/images/logo.svg')

    return <Img src={logo} alt="Mitsubishi Electric logo" />
}

export default Logo
