import React, { FC } from 'react'
import styled from 'styled-components'

import { SecondaryLink, Image, ProductList } from '~/components'
import { IGuideline } from '~/ts/interfaces'

interface Props {
    guideline: IGuideline
    hidden: boolean
}

const Container = styled.article`
    border-bottom: 1px solid ${({ theme }) => theme.colors.background.border};
    display: grid;
    grid-gap: ${({ theme }) => theme.spacings.xlarge};
    grid-template-columns: 1fr;
    padding-bottom: ${({ theme }) => theme.spacings.large};

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
        grid-template-columns: 150px 1fr;
    }
`

const Info = styled.div`
    display: flex;
    flex-flow: column;

    a {
        margin-top: ${({ theme }) => theme.spacings.large};
    }
`

const Title = styled.h3``

const ProductGroup = styled.p`
    margin-bottom: ${({ theme }) => theme.spacings.xsmall};
`

const LanguageContainer = styled.div`
    font-size: ${({ theme }) => theme.typography.typeScale.button};
    margin-bottom: ${({ theme }) => theme.spacings.large};

    span {
        color: ${({ theme }) => theme.colors.text.listItem};
        text-transform: capitalize;
    }
`

const ProductsTitle = styled.p`
    font-size: ${({ theme }) => theme.typography.typeScale.button};
    margin-bottom: 0.5rem;
`

const Guideline: FC<Props> = ({ guideline, hidden = false }) => {
    const fileType = String(guideline.FileType).toUpperCase()
    return (
        <Container hidden={hidden}>
            <a href={guideline.Link}>
                <Image src={guideline.Thumbnail} alt={Title ?? 'Thumbnail'} maxHeight="250px" />
            </a>
            <Info>
                <Title>{guideline.Title}</Title>
                <ProductGroup>{guideline.ProductGroup}</ProductGroup>
                <LanguageContainer>
                    Språk: <span>{guideline.Language}</span>
                </LanguageContainer>
                <ProductsTitle>Tillhörande modeller:</ProductsTitle>
                <ProductList products={guideline.Products} />
                <SecondaryLink href={guideline.Link}>{`Ladda ned ${fileType}`}</SecondaryLink>
            </Info>
        </Container>
    )
}

export default Guideline
