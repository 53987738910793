import { get } from '~/utils/net'
import { API_URL } from '~/config'

export function getAll() {
    return get(`${API_URL}`)
}

export function getAllDocuments() {
    return get(`${API_URL}?Doctypes=1`)
}

export function getProducts(productCategory: number) {
    return get(`${API_URL}?Products=${productCategory}`)
}
export function getGuidelines() {
    return get(`${API_URL}?Permission=4&Doctype=Guideline`)
}
export function getProductsByGroup(productCategory: number, productGroup: string) {
    return get(`${API_URL}?Products=${productCategory}&ProductGroup=${productGroup}`)
}

export function getDocTypes() {
    return get(`${API_URL}?Doctypes=1`)
}

export function getProductGroups(number: any = 1) {
    return get(`${API_URL}?ProductGroups=${number}`)
}

export default {
    getAllDocuments,
    getProductGroups,
    getProductsByGroup,
    getAll,
    getDocTypes,
}
