import React, { FC, createContext, useReducer, Dispatch, useContext, Reducer } from 'react'
import { IState } from '~/ts/interfaces'
import { Actions } from './actions'
import { initialState } from './initialState'

interface IStoreContext {
    state: IState
}
interface IStoreDispatchContext {
    dispatch: Dispatch<Actions>
}

// Do we have to set initialState here?? We want to set it in App.tsx
const StateProvider = createContext<IStoreContext>({
    state: initialState,
})

const DispatchProvider = createContext<IStoreDispatchContext>({
    dispatch: () => null,
})

export interface StoreProviderProps {
    initialState: IState
    reducer: Reducer<IState, Actions>
}

const StoreProvider: FC<StoreProviderProps> = ({ children, initialState, reducer }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <StateProvider.Provider value={{ state }} displayName="Store">
            <DispatchProvider.Provider value={{ dispatch }} displayName="Dispatcher">
                {children}
            </DispatchProvider.Provider>
        </StateProvider.Provider>
    )
}

export const useStore = () => useContext(StateProvider)
export const useStoreDispatch = () => useContext(DispatchProvider)
export default StoreProvider
