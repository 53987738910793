import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

import FilterActions from '../../store/filters/actions'
import history from '../../routerHistory'
import queryString from 'query-string'
import { GlobalStyles } from '~/utils/theme'
import { IFilter } from '~/ts/interfaces'
import { useStoreDispatch } from '~/store'
import { Footer, Header, Main } from '~/components/'

interface Props {
    children: ReactNode
}

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow-x: hidden;

    &.mousedown {
        *:focus {
            outline: none;
        }
    }
`
const Layout = ({ children }: Props) => {
    const [mouseDown, setMouseDown] = useState(false)
    const { dispatch } = useStoreDispatch()

    useEffect(() => {
        document.addEventListener('mousedown', () => setMouseDown(true))
        document.addEventListener('keydown', () => setMouseDown(false))

        return () => {
            document.removeEventListener('mouseDown', () => {})
            document.removeEventListener('keydown', () => {})
        }
    }, [])

    useEffect(() => {
        const parsed = (queryString.parse(history.location.search) as unknown) as IFilter
        dispatch(FilterActions.setFilters(parsed))
    }, [])

    return (
        <>
            <GlobalStyles />
            <Wrapper className={mouseDown ? 'mousedown' : ''}>
                <Header />
                <Main>{children}</Main>
                <Footer />
            </Wrapper>
        </>
    )
}

export default Layout
