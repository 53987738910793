import { IFilter } from '~/ts/interfaces'
import { FilterActionTypes } from '~/ts/enums'

interface ISelectProduct {
    type: FilterActionTypes.SET_SELECTED_PRODUCT
    payload: string
}
interface ISelectProductGroup {
    type: FilterActionTypes.SET_SELECTED_PRODUCT_GROUP
    payload: string
}
interface ISetSearchText {
    type: FilterActionTypes.SET_SEARCH_TEXT
    payload: string
}
interface ISetFilters {
    type: FilterActionTypes.SET_FILTERS
    payload: IFilter
}
interface IResetFilters {
    type: FilterActionTypes.RESET_FILTERS
}

const setSelectedProduct = (payload: string): ISelectProduct => ({
    type: FilterActionTypes.SET_SELECTED_PRODUCT,
    payload,
})

const setSelectedProductGroup = (payload: string): ISelectProductGroup => ({
    type: FilterActionTypes.SET_SELECTED_PRODUCT_GROUP,
    payload,
})
const setSearchText = (payload: string): ISetSearchText => ({
    type: FilterActionTypes.SET_SEARCH_TEXT,
    payload,
})
const setFilters = (payload: IFilter): ISetFilters => ({
    type: FilterActionTypes.SET_FILTERS,
    payload,
})
const resetFilters = (): IResetFilters => ({
    type: FilterActionTypes.RESET_FILTERS
})

export type FilterActions = ISelectProduct | ISelectProductGroup | ISetSearchText | ISetFilters | IResetFilters
export default { setSelectedProduct, setSelectedProductGroup, setSearchText, setFilters,resetFilters }
