import React, { FC, useState, useEffect } from 'react'
import QrReader from 'react-qr-reader'
import styled from 'styled-components'

import { PrimaryLink } from '..'
import { IScanData } from '~/ts/interfaces'

interface IProps {
    setScanResult: (scanResult: IScanData) => void
    setShowQrScanner: (showQrScanner: boolean) => void
    showQrScanner: boolean
}

const Container = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;

    button {
        margin: 0;
        width: 100%;
    }

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
        display: none;
    }
`

const Qr = styled(QrReader)`
    margin-bottom: 1rem;
`

const Icon = styled.img`
    margin-right: 1rem;
    max-width: 23px;
`

const ScanningStatus = styled.div`
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    img { 
        max-width: 40px;
        padding-right: 1rem;
    }
`

const QrScanner: FC<IProps> = ({ setScanResult, setShowQrScanner, showQrScanner }) => {
    const scanning = require('~/assets/images/scanning.svg')
    const [scanData, setScanData] = useState('')
    const icon = require('~/assets/images/camera.svg')
    const [isScanning, setIsScanning] = useState(true)

    const handleError = (err: any) => {
        console.error(err)
    }

    useEffect(() => {
        setIsScanning(!isScanning)

        if(scanData) {
            const result = {data: scanData} as IScanData
            setScanResult(result)
            setIsScanning(!isScanning)
        }
    }, [scanData])

    return (
        <Container>
            {showQrScanner &&
            <>
                <p>Skanna koden på modellskylten.</p>
                <Qr
                    legacyMode={false}
                    onError={handleError}
                    onScan={(scanData: any) => setScanData(scanData)}
                    showViewFinder
                    style={{width: '100%'}}
                />
                <ScanningStatus>
                    <img src={scanning} alt="scanning" />
                    <span>{isScanning ? 'Skannar...' : 'Inget resultat på QR-kod'}</span>
                </ScanningStatus>
            </>
            }
            <PrimaryLink 
                as="button"
                onClick={() => setShowQrScanner(!showQrScanner)}
            >
                {!showQrScanner &&
                    <Icon src={icon} alt="Skanna med QR-kod" />
                }
                {showQrScanner ? 'Dölj QR-skanner' : 'Skanna en QR-kod'}
            </PrimaryLink>
        </Container>
    )
}

export default QrScanner
