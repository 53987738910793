import { sizes } from './'

export interface Spacings {
    layoutWrapper: string
    filter: string
    borderRadius: string
    gridGap: string
    xssmall: string
    xsmall: string
    small: string
    medium: string
    large: string
    intermediate: string
    xlarge: string
    xxlarge: string
}

const spacings: Spacings = {
    layoutWrapper: `calc(20px + 10 * ((100vw - 375px) / 1065))`,
    filter: `${sizes[212]}`,
    borderRadius: '0.187rem',
    gridGap: `${sizes[250]}`,
    xssmall: `${sizes[40]}rem`,
    xsmall: `${sizes[50]}rem`,
    small: `${sizes[75]}rem`,
    medium: `${sizes[100]}rem`,
    intermediate: `${sizes[125]}rem`,
    large: `${sizes[150]}rem`,
    xlarge: `${sizes[188]}rem`,
    xxlarge: `${sizes[375]}rem`,
}

export default spacings
