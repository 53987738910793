import React, { FC, useEffect } from 'react'
import { useSelect } from 'downshift'
import styled, { withTheme } from 'styled-components'

import { Theme } from '~/utils/theme/theme'

interface IDropdownProps {
    onSelectedItemChange?: (changes: any) => void
    items: string[]
    label: string
    theme?: Theme
    selectedItem: string
}

const Container = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;
`

interface ButtonProps {
    hasSelectedItem: boolean
    isOpen: boolean
}

const Button = styled.button<ButtonProps>`
    background-color: ${({ theme }) => theme.colors.dropdowns.primary};
    border-radius: ${({ theme }) => theme.spacings.borderRadius};
    border: ${({ theme }) => `1px solid ${theme.colors.dropdowns.primaryBorder}`};
    color: ${({ hasSelectedItem }) => (hasSelectedItem ? '#212121' : '#b5b5b5')};
    cursor: pointer;
    display: block;
    font-size: 1rem;
    line-height: 1.5625rem;
    margin-bottom: 0;
    overflow: hidden;
    padding: 0.9375rem 49px 0.9375rem 0.9375rem;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 7' style='enable-background:new 0 0 10 7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23ce2323;stroke-width:2;%7D%0A%3C/style%3E%3Cpolyline id='Path' class='st0' points='9.2,1 5,5.3 0.8,1 '/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 10px;
        bottom: 0;
        padding: 22px;
        position: absolute;
        right: 5px;
        top: 0;
        transition: all 0.3s ease-in-out 0s;
        transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
    }

    &:disabled {
        background: ${({ theme }) => theme.colors.dropdowns.primaryDisabled};
        color: ${({ theme }) => theme.colors.dropdowns.primaryDisabled};
    }

    &.isOpen {
        border: ${({ theme }) => `1px solid ${theme.colors.dropdowns.primaryBorderOpen}`};
        border-bottom-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`

interface ListProps {
    isOpen: boolean
}

const List = styled.ul<ListProps>`
    background: #fff;
    border-radius: ${({ theme }) =>
        `0 0 ${theme.spacings.borderRadius} ${theme.spacings.borderRadius}`};
    border: ${({ isOpen, theme }) =>
        isOpen ? `1px solid ${theme.colors.dropdowns.primaryBorderOpen}` : ''};
    border-top: none;
    cursor: pointer;
    max-height: 315px;
    overflow-y: scroll;
    position: absolute;
    top: 56px;
    transform-origin: 50% 0;
    transform: ${({ isOpen }) => (isOpen ? 'scale(1, 1)' : 'scale(1, 0)')};
    width: 100%;
    z-index: 1;
`

const ListItem = styled.li`
    cursor: pointer;
    padding: 0.9375rem;
    color: ${({ theme }) => theme.colors.dropdowns.primaryListText};
`

const ListItemLabel = styled.label<ListProps>`
    word-break: break-all;
    vertical-align: middle;
    transition: opacity 0.05s ease-in-out;
    transition-delay: 0.2s;
    margin-bottom: 0;
    cursor: pointer;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`

function stateReducer(state: any, actionAndChanges: any) {
    const { type, changes } = actionAndChanges
    // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
    switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.ItemClick:
            return {
                ...changes, // default Downshift new state changes on item selection.
                isOpen: !state.isOpen, // but keep menu open.
                highlightedIndex: state.highlightedIndex, // with the item highlighted.
            }
        default:
            return changes // otherwise business as usual.
    }
}

const Dropdown: FC<IDropdownProps> = ({
    onSelectedItemChange,
    items,
    selectedItem,
    label,
    theme,
}) => {
    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        reset,
        getItemProps,
    } = useSelect({ items, stateReducer, onSelectedItemChange })

    useEffect(() => {
        if (items.length <= 0) {
            reset()
        }
    }, [items])
    return (
        <Container>
            <Button
                className={isOpen ? 'isOpen' : ''}
                disabled={!items?.length}
                hasSelectedItem={!!selectedItem}
                isOpen={isOpen}
                {...getToggleButtonProps()}
            >
                {selectedItem || `${label}`}
            </Button>
            <List
                aria-expanded={isOpen}
                isOpen={isOpen}
                role="listbox"
                tabIndex="-1"
                {...getMenuProps()}
            >
                {items.map((item, index) => (
                    <ListItem
                        isHighlighted={highlightedIndex === index}
                        style={
                            highlightedIndex === index
                                ? {
                                      backgroundColor: `${theme?.colors.dropdowns.primaryHighlighted}`,
                                  }
                                : {}
                        }
                        key={`${item}${index}`}
                        {...getItemProps({ item, index })}
                        role="options"
                    >
                        <ListItemLabel isOpen={isOpen}>{item}</ListItemLabel>
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

export default withTheme(Dropdown)
