import { IFilter, IGuideline } from '~/ts/interfaces'


const filterRegex = /\W/g;

export const FilterGuidelinesSet = (filter: IFilter, guidelines: object): Set<string> => {
    const { searchText, selectedProduct, selectedProductGroup } = filter
    const set = new Set<string>(Object.keys(guidelines))

    for (const guideLineKey of Object.keys(guidelines)) {
        const o = guidelines[guideLineKey] as IGuideline
        if (selectedProductGroup && selectedProductGroup !== o.ProductGroup) {
            set.delete(guideLineKey)
            continue
        }

        if (selectedProduct && !o.Products.includes(selectedProduct)) {
            set.delete(guideLineKey)
            continue
        }

        if (searchText.length >= 3) {
            let hasProduct = false
            const searchTextFixed = searchText.toUpperCase().replace(filterRegex,'')
            for (const p of o.Products) {
                if (p.toUpperCase().replace(filterRegex ,'').indexOf(searchTextFixed) > -1) {
                    hasProduct = true
                    break
                }
            }
            if (o.Title.toUpperCase().indexOf(searchTextFixed) < 0 && !hasProduct) {
                set.delete(guideLineKey)
                continue
            }
        }
    }

    return set
}
export default FilterGuidelinesSet
