import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
    grid-gap: ${({ theme }) => theme.spacings.xlarge};

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobileL}) {
        grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
    }
`

const Grid: FC = ({ children }) => {
    return <Container>{children}</Container>
}

export default Grid
