enum FilterActionTypes {
    SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
    SET_SELECTED_PRODUCT_GROUP = 'SET_SELECTED_PRODUCT_GROUP',
    SET_SEARCH_TEXT = 'SET_SEARCH_TEXT',
    SET_FILTERS = 'SET_FILTERS',
    RESET_FILTERS = 'RESET_FILTERS',
    
}

export default FilterActionTypes
