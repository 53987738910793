import { Actions } from 'store/actions'
import { ProductGroupActionTypes } from '~/ts/enums'
import { IProductGroupState } from '~/ts/interfaces'

export const productgroupReducer = (state: IProductGroupState, action: Actions) => {
    switch (action.type) {
        case ProductGroupActionTypes.FETCHING:
            return {
                ...state,
                isFetching: true,
            }
        case ProductGroupActionTypes.FETCHED:
            const { payload } = action
            return {
                ...state,
                isFetching: false,
                data: payload,
            }
        case ProductGroupActionTypes.REJECTED:
            return {
                ...state,
                isFetching: false,
            }
        default:
            return state
    }
}
