import { GuidelineActionTypes } from '~/ts/enums'
import { IGuidelineState } from '~/ts/interfaces'
import { Actions } from 'store/actions'

export const guidelineReducer = (state: IGuidelineState, action: Actions) => {
    switch (action.type) {
        case GuidelineActionTypes.FETCHING:
            return {
                ...state,
                isFetching: true,
            }
        case GuidelineActionTypes.FETCHED:
            return {
                ...state,
                isFetching: false,
                data: state.data,
            }
        case GuidelineActionTypes.REJECTED:
            return {
                ...state,
                isFetching: false,
            }
        default:
            return state
    }
}
