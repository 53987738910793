const colors = {
    neutral: {
        100: 'rgb(255, 255, 255)',
        150: 'rgb(248, 248, 248)', // #f8f8f8
        200: 'rgb(181, 181, 181)', // #b5b5b5
        300: 'rgb(158, 158, 158)', // #9e9e9e
        400: 'rgb(118, 118, 118)',
        500: 'rgb(87, 87, 87)',
        600: 'rgb(64, 64, 64)',
        800: 'rgb(16, 16, 16)', // #101010
        900: 'rgba(0,0,0,0.8)',
        950: 'rgb(9, 9, 9)',
        1000: 'rgb(0, 0, 0)',
    },
    red: {
        50: 'rgba(206, 35, 35, 0.65)',
        75: 'rgb(245, 58, 82)',
        100: 'rgb(206, 35, 35)',
        200: 'rgb(167, 5, 5)',
    },
    blue: {
        100: 'rgb(0, 113, 188)',
    },
}

export default colors
