import React, { ReactElement } from 'react'
import dotenv from 'dotenv'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import StoreProvider from './store'
import history from './routerHistory'
import { Layout, FiltersAndGuidelines } from '~/components'
import { initialState } from './store/initialState'
import { mainReducer } from './store/reducers'
import { theme } from '~/utils/theme'

dotenv.config()

function App(): ReactElement {
    return (
        <Router history={history}>
            <StoreProvider initialState={initialState} reducer={mainReducer}>
                <ThemeProvider theme={theme}>
                    <Layout>
                        <FiltersAndGuidelines />
                    </Layout>
                </ThemeProvider>
            </StoreProvider>
        </Router>
    )
}

export default App
