import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;

    img {
        width: 8rem;
        margin-bottom: 1rem;
    }

    p {
        position: absolute;
    }
`

const Loading: FC = () => {
    const loading = require('~/assets/images/loading.svg')
    return (
        <Container>
            <img src={loading} alt="Loading..."/>
            <p>Loading</p>
        </Container>
    )
}

export default Loading