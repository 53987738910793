import { IGuideline, IStateData } from '~/ts/interfaces'
import { initialDocumentTypesState } from './documentTypes/inititalDocumentTypesState'
import { initialGuidelineState } from './guidelines/initialGuidelineState'
import { initialProductGroupState } from './productGroup/InitialProductGroupState'

export const initialDataState: IStateData = {
    productGroup: initialProductGroupState,
    documentTypes: initialDocumentTypesState,
    guidelines: initialGuidelineState,
    filteredGuidelines: [] as IGuideline[],
}
