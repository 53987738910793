const sizes = {
    0: 0,
    40: 0.375,
    50: 0.5,
    75: 0.75,
    100: 1, // (base size)
    125: 1.25,
    150: 1.5,
    188: 1.88,
    212: 2.125,
    250: 2.5,
    375: 3.75,
}

export default sizes
