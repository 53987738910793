import React from 'react'
import styled from 'styled-components'

const Outer = styled.div`
    width: 23px;
    height: 23px;
    position: relative;
    display: flex;
    align-self: center;
    position: absolute;
    right: 1.4375rem;
    top: 50%;
    transform: translateY(-50%);
`
const Inner = styled.div`
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.background.border};
        transform: rotate(-45deg);
        transform-origin: right top;
        right: 1px;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 55%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.background.border};
        transform: rotate(45deg);
        transform-origin: right bottom;
        right: 1px;
    }
`
const Icon = styled.div`
    position: absolute;
    display: block;
    width: 73.3333%;
    height: 73.3333%;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.background.border};
`

const SearchIcon = () => {
    return (
        <Outer>
            <Inner>
                <Icon />
            </Inner>
        </Outer>
    )
}

export default SearchIcon
