import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.footer`
    align-items: center;
    background: ${({ theme }) => theme.colors.background.tertiary};
    display: flex;
    justify-content: center;
    padding: 0.9375rem 0;
    width: 100%;

    p {
        margin: 0;
        color: ${({ theme }) => theme.colors.text.onTernary};
    }
`

const Footer: FC = () => {
    return (
        <Container>
            <p>© Mitsubishi Electric Sweden</p>
        </Container>
    )
}

export default Footer
