import React, { useState } from 'react'
import styled from 'styled-components'

import { TernaryLink } from '~/components/'

interface IProps {
    products: string[]
}

interface IContainer {
    isLinkHidden: boolean
}

const Container = styled.div<IContainer>`
    flex: 1;

    a {
        display: ${({ isLinkHidden }) => (isLinkHidden ? 'none' : 'block')};
        text-align: center;
        margin: 0;
    }
`

const Details = styled.details`
    margin-bottom: 1rem;
`
const Summary = styled.summary`
    list-style: none;
    position: relative;

    &::-webkit-details-marker {
        display: none;
    }
`

const DefaultList = styled.ul`
    list-style: none;

    li {
        padding-bottom: ${({ theme }) => theme.spacings.xssmall};
    }
`
interface ISummaryList {
    hasShowMore: boolean
    isOpen: boolean
}

const SummaryList = styled(DefaultList)<ISummaryList>`
    list-style: none;
    position: relative;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${({ hasShowMore, isOpen }) =>
            hasShowMore && !isOpen
                ? 'linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%)'
                : 'none'};
        z-index: 10;
    }
`

const DetailsList = styled(DefaultList)``

const ProductList = ({ products }: IProps) => {
    const [open, setOpen] = useState(false)
    const cut = 3
    const summaryProducts = products.slice(0, cut)
    const detailsProducts = products.slice(cut, products.length)
    const hasShowMore = products?.length > cut

    return (
        <>
            {products.length > 0 && (
                <Container isLinkHidden={!hasShowMore}>
                    <Details open={open}>
                        <Summary>
                            <SummaryList hasShowMore={hasShowMore} isOpen={open}>
                                {summaryProducts.map((product, i) => (
                                    <li key={i}>{product}</li>
                                ))}
                            </SummaryList>
                        </Summary>
                        <DetailsList>
                            {detailsProducts.map((product, i) => (
                                <li key={i}>{product}</li>
                            ))}
                        </DetailsList>
                    </Details>
                    <TernaryLink onClick={() => setOpen(!open)}>
                        {open ? 'Visa färre modeller' : 'Visa fler modeller'}
                    </TernaryLink>
                </Container>
            )}
        </>
    )
}

export default ProductList
