import React, { FC, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { IGuidelines, IGuidelineApiResponse } from '~/ts/interfaces'
import { useStore } from '~/store'
import { Guideline } from '~/components'
import { createGuidelinesObj, FilterGuidelinesSet } from '~/utils/helpers/'

const Container = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
    grid-gap: ${({ theme }) => `${theme.spacings.gridGap}rem`};

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
        grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
    }
`
interface GuidelinesProps {
    guidelineResponse: IGuidelineApiResponse
}
const Guidelines: FC<GuidelinesProps> = ({ guidelineResponse }) => {
    const {
        state: { filters },
    } = useStore()

    const [guidelines, setGuidelines] = useState<IGuidelines>({})
    const [visibles, setVisibles] = useState(new Set<string>())

    useEffect(() => {
        if (guidelineResponse.items.length > 0) {
            setGuidelines(createGuidelinesObj(guidelineResponse))
        }
    }, [guidelineResponse])

    useEffect(() => {
        if (Object.keys(guidelines).length > 0)
            setVisibles(FilterGuidelinesSet(filters, guidelines))
    }, [filters.searchText, filters.selectedProduct, filters.selectedProductGroup, guidelines])

    const guidelinesList = useMemo(
        () => <GuidelinesList guidelines={guidelines} visibles={visibles} />,
        [visibles],
    )

    return <Container>{guidelinesList}</Container>
}

const GuidelinesList = ({
    guidelines,
    visibles,
}: {
    guidelines: IGuidelines
    visibles: Set<string>
}) => (
    <>
        {Object.keys(guidelines).map((key, index) => (
            <Guideline
                hidden={!visibles.has(key)}
                guideline={guidelines[key]}
                key={`guideline-${index}`}
            />
        ))}
    </>
)

export default Guidelines
