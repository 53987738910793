import { IState } from '~/ts/interfaces'
import { initialDataState } from './data/inititalDataState'
import { initialFilterState } from './filters/initialFilterState'

const initialState: IState = {
    data: initialDataState,
    filters: initialFilterState,
}

export { initialState }
