import React, { FC } from 'react'
import styled from 'styled-components'

interface ResetFiltersProps {
    onClick?: () => void
    text: string
}

const Button = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => `${theme.colors.buttons.submitDisabled}`};
    cursor: pointer;
    font-size: ${({ theme }) => `${theme.typography.typeScale.button}`};
    font-weight: 500;
    letter-spacing: 0.03333333333rem;
    line-height: 1.125rem;
    margin-left: ${({ theme }) => `calc(-${theme.spacings.intermediate} / 2)`};
    margin-top: ${({ theme }) => `calc(${theme.spacings.intermediate} / 2)`};
    padding: ${({ theme }) => `calc(${theme.spacings.intermediate} / 2)`};
    white-space: nowrap;
`

const ResetFilters: FC<ResetFiltersProps> = ({ onClick, text }) => {
    return (
        <Button onClick={onClick}>
            {text}
        </Button>
    )
}

export default ResetFilters
